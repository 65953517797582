import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";

import apiClient from "@/plugins/apiClient";
import {
  saveStateToLocalStorage,
  getStateFromLocalStorage,
} from "./storeUtils.js";

const getCartFromLocalStorage = () => {
  return getStateFromLocalStorage("fairr-basket") || [];
}

export const useOrdersStore = defineStore("order", {
  state: () => ({
    orders: [],
    basket: getCartFromLocalStorage(),
    loading: false,
  }),

  actions: {
    isItemInBasket(productId) {
      return this.basket.some(({ orderItemForOrderCreations }) => orderItemForOrderCreations.some(({ id }) => id === productId));
    },

    itemsCount() {
      return this.basket.reduce((acc, item) => acc + item.orderItemForOrderCreations.length, 0);
    },

    setDeliveryOption(storeId, deliveryOptionId) {
      const order = this.basket.find(item => item.storeId === storeId);
      if (order) {
        order.deliveryOptionId = deliveryOptionId;
      }

      console.log("SetDeliveryOption result:", this.basket);

      saveStateToLocalStorage("fairr-basket", this.basket);
    },

    addToBasket(product) {
      const existingOrder = this.basket.find(item => item.storeId === product.storeId);
      const productOfferId = product.id;

      if (existingOrder && existingOrder.orderItemForOrderCreations) {
        const existingItem = existingOrder.orderItemForOrderCreations.find(item => item.productOfferId === productOfferId);
        if (existingItem) {
          existingItem.quantity += 1;
        } else {
          existingOrder.orderItemForOrderCreations.push({ ... product, productOfferId, quantity: 1 });
        }
      } else {
        this.basket.push({
          id: product.storeId,
          storeId: product.storeId,
          vendorId: product.vendorId,
          deliveryOptionId: 1,
          orderItemForOrderCreations: [{ ... product, productOfferId, quantity: 1 }],
        });
      }
      saveStateToLocalStorage("fairr-basket", this.basket);
    },

    removeFromBasket(productId) {
      this.basket.forEach(item => {
        item.orderItemForOrderCreations = item.orderItemForOrderCreations.filter(subitem => subitem.id !== productId);

        if (item.orderItemForOrderCreations.length === 0) {
          this.basket = this.basket.filter(({ storeId }) => storeId !== item.storeId);
        }
      });

      saveStateToLocalStorage("fairr-basket", this.basket);
    },

    clearBasket(orders) {
      this.basket = this.basket.filter(order => !orders.some(i => i.storeId === order.storeId));
      saveStateToLocalStorage("fairr-basket", this.basket);
    },

    updateProductQuantity(productId, quantity) {
      
      const order = this.basket.find(({ orderItemForOrderCreations }) => orderItemForOrderCreations.some(({ id }) => id === productId));
      if (order) {
        const item = order.orderItemForOrderCreations.find(({ id }) => id === productId);
        if (item) {
          item.quantity = quantity;
        }
        console.log("Updated product quantity", product);
        saveStateToLocalStorage("fairr-basket", this.basket);
      }

    },

    setSelectedStore(storeId) {
      const index = this.basket.findIndex(item => item.storeId === storeId);
      this.basket[index].selected = true;

      saveStateToLocalStorage("fairr-basket", this.basket);
    },

    setDeselectedStore(storeId) {
      const index = this.basket.findIndex(item => item.storeId === storeId);
      this.basket[index].selected = false;

      saveStateToLocalStorage("fairr-basket", this.basket);
    },

    emptyBasket() {
      this.basket = [];
      saveStateToLocalStorage("fairr-basket", this.basket);
    },

    cleanEmptyOrders() {
      this.basket = this.basket.filter(({ orderItemForOrderCreations }) => orderItemForOrderCreations.length > 0);
      saveStateToLocalStorage("fairr-basket", this.basket);
    },

    async submitOrder({ items, description }) {
      if (this.loading) return;

      try {
        if (items.length === 0) {
          console.error("Failed to submit order: No items in the basket");  
          return;
        }

        this.loading = true;

        console.log("Submitting order", items);

        const formattedOrderItems = items.map(({storeId, vendorId, deliveryOptionId, orderItemForOrderCreations}) => {
          return {
            storeId: storeId,
            vendorId: vendorId,
            deliveryOptionId: deliveryOptionId,
            orderItemForOrderCreations: orderItemForOrderCreations.map(({ productOfferId, quantity }) => ({
              productOfferId: productOfferId,
              quantity: `${quantity}`,
            })),
          }
        });


        console.log("Submitting order", formattedOrderItems);

        const orderId = (await apiClient.post('/orders', {
          description,
          items: formattedOrderItems
        })).data

        this.clearBasket(items);
        return orderId;
      } catch (err) {
        console.error("Failed to submit order", err);
      } finally {
        this.loading = false;
      }
    },

    async fetchOrders() {
      try {
        this.loading = true;
        const response = (await apiClient.get('/orders/for-customer')).data;
        this.orders = mapOrders(response);
        console.log(this.orders);
      } catch (err) {
        console.error("Failed to fetch orders", err);
      } finally {
        this.loading = false;
      }
    }
  },
});

const mapOrders = (orders) => {
  return orders.reduce((acc, order) => {
    const { orderDate, id, storeOrders } = order;
    const formattedOrders = storeOrders.map((storeOrder) => {
      return {
        ...storeOrder,
        orderDate,
        orderId: id,
        uuid: uuidv4(),
      }
    })

    acc.push(...formattedOrders);

    return acc;
  }, [])
}
